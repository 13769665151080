import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['menu', 'button']

  toggle(e) {
    e.stopPropagation()

    if (this.menuTarget.classList.contains('hidden')) {
      this.menuTarget.classList.remove('hidden')
      this.menuTarget.classList.add('block')
    } else {
      this.menuTarget.classList.add('hidden')
      this.menuTarget.classList.remove('block')
    }
  }
}
