// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import ScrollReveal from 'stimulus-scroll-reveal'
import { Dropdown } from "tailwindcss-stimulus-components"


const application = Application.start()
application.register('scroll-reveal', ScrollReveal)

const context = require.context("controllers", true, /_controller.js$/)
application.load(definitionsFromContext(context))

application.register('dropdown', Dropdown)
