// app/javascript/controllers/iframe_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loading"]
  static values = {
    url: String
  }

  connect() {
    this._loadIframe()
    // window.addEventListener('message', this.resizeIframe.bind(this))
  }

  _loadIframe() {
    const iframe = document.createElement("iframe")

    window.addEventListener("message", (event) => {
      this.resizeIframe(event, iframe)
    })

    iframe.onload = () => {
      this.loadingTarget.style.display = 'none'
    }

    iframe.src = this.urlValue
    iframe.className = "w-full"

    this.element.appendChild(iframe)
  }

  resizeIframe(event, iframe) {
    console.log(event)
    if (event.data?.type === "set_height") {
      iframe.style.height = event.data.value + "px"
    }
  }
}
