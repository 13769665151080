import { Controller } from "@hotwired/stimulus"
import { Dropdown } from "tailwindcss-stimulus-components"

export default class LocaleBannerController extends Dropdown {
  static targets = ['currentLocale', 'otherLocale', 'link']

  connect() {
    super.connect()
  }

  toggleLocale() {
    const switchToLocaleText = this.otherLocaleTarget.innerText
    const switchToLocaleKey = this.otherLocaleTarget.dataset.locale

    const currentLocaleText = this.currentLocaleTarget.innerText
    const currentLocalekey = this.currentLocaleTarget.dataset.locale

    this.currentLocaleTarget.innerText = switchToLocaleText
    this.currentLocaleTarget.setAttribute('data-locale', switchToLocaleKey)

    this.toggle()

    this.otherLocaleTarget.innerText = currentLocaleText
    this.otherLocaleTarget.setAttribute('data-locale', currentLocalekey)

    this.linkTarget.href = this.linkTarget.href.replace(currentLocalekey, switchToLocaleKey)
  }
}
